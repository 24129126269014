<template>
  <Row :showBorder="showBorder" v-if="ready">
      
      <EmailDialog :inspectionId="inspection.id()" v-show="showEmailDialog" @close="closeEmailDialog" />
      <RemarksDialog :inspectionId="inspection.id()" v-show="showRemarksDialog" @close="closeRemarksDialog" />
      
      <div class="rc-box-col-1">
        <Button :disabled="isBusy" v-on:click="details" v-if="selected != 'details'">
          {{ MC.Actions.Details.value() }}
        </Button>
      </div>

      <Column :width="3" />
      
      <div class="rc-box-col-1">
        <Button v-if="isAdmin" v-on:click="deleteInspection" :disabled="isBusy">
        {{ MC.Actions.Delete.value() }}
        </Button>
      </div>
      
      <Column :width="3" />
      <div class="rc-box-col-1">
        <Button v-if="isAdmin" v-on:click="rebuild" :disabled="isBusy">
        {{ MC.Actions.Rebuild.value() }}
        </Button>
      </div>

      <div class="rc-box-col-2">
        <Button v-on:click="print" :disabled="isBusy">
          {{ MC.Actions.PrintDownload.value() }}
        </Button>
      </div>
      
      <div  class="rc-box-col-1">
        <Button v-if="hasUnfixedDefects" 
          v-on:click="repairs" :disabled="isBusy">
          {{ MC.Actions.Repair.value() }}
        </Button>
      </div>
     
      <div class="rc-box-col-1">
        <Button v-on:click="email" :disabled="isBusy">
        {{ MC.Actions.EmailMto.value() }}
        </Button>
      </div>

      <div class="rc-box-col-2">
        <Button
          v-on:click="remarks" :disabled="isBusy">
          {{ MC.Actions.AddRemarks.value() }}
        </Button>
      </div>
        
</Row>

</template>

<script>
import { mapGetters } from 'vuex';

import ConstUtils from '@/utils/ConstUtils.js';
import ContentUtils from '@/utils/ContentUtils.js';

import MC from "@/domain/session/MC.js";

import Inspection from "@/domain/model/inspection/Inspection.js";

import Row    from '@/portals/shared/library/box/Row.vue';
import Column from '@/portals/shared/library/box/Column.vue';

import Button from '@/portals/shared/library/button/Button.vue';

import EmailDialog from "@/portals/customer/operator/views/inspection/email/EmailDialog.vue";
import RemarksDialog from "@/portals/customer/operator/views/inspection/remarks/RemarksDialog.vue";

export default {
  name: 'inspection-menu',
  components: {
    Button,
    Row,Column,
    EmailDialog, RemarksDialog,
  },
  props: {
    inspectionId: { type: String, default: null },
    selected:     { type: String, default: null },
    isAdmin:      { type: Boolean, default: false },
    showBorder:   { type: Boolean, default: false },
  },
  data() {
    return {
      ready: false,
      MC: new MC(),
      showEmailDialog: false,
      showRemarksDialog: false,
      ContentUtils: ContentUtils,
      isBusy: false,
    }
  },
  watch: {
    inspectionId() {
      this.domain.logger().info("inspection changed:: MenuRow");
    }
  },
  computed: {
    ...mapGetters([
      "auth_client",
      'domain',
    ]),
    inspection: function() {
      if (this.domain) {
        return this.domain.inspections().findById(this.inspectionId);
      }
      return new Inspection(this.domain);
    },
    hasUnfixedDefects: function() {
      return this.inspection.hasUnfixedDefect();
    },
  },
  mounted: function() {
    this.ready = true;
  },
  methods: {
    details: function () {
      var params = {
        id: this.inspection.id(),
      }
      this.$router.push({
        name: ConstUtils.ROUTES.INSPECTION_DETAILS,
        params: params,
      })
    },
    repairs: function() {
      var params = {
        id: this.inspection.id(),
      };
      this.$router.push({
        name: ConstUtils.ROUTES.INSPECTION_REPAIRS,
        params: params,
      });
    },
    rebuild: function() {
      this.isBusy = true;
      var event = this.domain
          .events()
          .inspections().rebuild(this.inspection.id());
      event.send(this.listener);
    },
    deleteInspection: function() {
      this.isBusy = true;
      var event = this.domain
          .events()
          .inspections().deleteInspection(this.inspection.id());
      event.send(this.deleteListener);
    },
    deleteListener: function(event) {
      if (event) {
        //
      }
      this.$emit("refresh");
    },
    listener: function(event) {
      this.isBusy = false;
      if (event) {
        //
      }      
    },
    refresh: function() {
      this.$emit('refresh');
    },
    
    
    email: function() {
      this.showEmailDialog = true;
    },
    closeEmailDialog: function() {
      this.showEmailDialog = false;  
    },
    print: function() {
      var url = ContentUtils.escape(this.inspection.pdfUrl());
      url = url.replace("#", "%23");
      window.open(url, '_blank');
    },
    closeRemarksDialog: function(good) {
      this.showRemarksDialog = false;
      if (good) {
        this.refresh();
      }
    },
     
    remarks: function() {
      this.showRemarksDialog = true;
    },
  },
}
</script>
